/* eslint-disable react/no-array-index-key */
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "../components/background-image"
import queryHeroImage from "../../../hooks/query-hero-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const SplitGeneric = ({ section, index, ...props }: SectionProps) => {
  const link = section.links?.length > 0 ? section.links[0] : undefined
  const splitImage = queryHeroImage(link?.url)

  return (
    <div className={`relative w-full`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto sm:px-6 lg:px-0 h-full">
        {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <div className="w-full h-full md:grid md:grid-cols-2 md:gap-6">
          <div className={`relative w-full h-full ${section.advanced?.class || ``}`}>
            <div className="h-full mx-4 md:mx-12 flex flex-col content-center">{section.blurb2 && <div dangerouslySetInnerHTML={createMarkup(section.blurb2)} />}</div>
            <div className="clear-both"></div>
          </div>

          <div className={`relative w-full h-full ${section.advanced?.class2 || ``}`}>
            {splitImage ? (
              <GatsbyImage image={getImage(splitImage?.node)} style={{ height: `100%`, width: `100%` }} imgStyle={{ objectFit: "contain" }} alt={link?.name} />
            ) : (
              <img
                src={link?.url}
                style={{
                  height: `100%`,
                }}
                alt={link?.name}
              />
            )}
            <div className="clear-both"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitGeneric